$cdnUrl: 'https://cdn.harisenin.net';
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "animate";
@import "popover";
@import "typography";
@import "misc";
@import "misc/alert";
@import "misc/radio";
@import "misc/checkbox";
@import "vendor/date-picker";
@import "vendor/player";

// @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=DM+Sans:300,400,500,600,700,800&display=swap");

// .grecaptcha-badge {
//   width: 70px !important;
//   overflow: hidden !important;
//   transition: all 0.3s ease !important;
//   left: 4px !important;
// }

// .grecaptcha-badge:hover {
//   width: 256px !important;
// }

.grecaptcha-badge {
  visibility: hidden;
}

@layer base {
  h1 {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #222325;
  }

  body {
    position: relative;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: theme("colors.green");
  }

  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-inline-start: 20px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }


  button {
    &:focus {
      outline: none
    }
  }
}

@import "material";